import { CategoryTitle, Container, FlexContainer } from '@cheddartv/storybook-news'

import { WhereToWatch } from '../../types'
import PlatformsList from '../WhereToWatch/PlatformsList'

export type WhereToWatchSectionProps = Omit<WhereToWatch, 'name'>

const WhereToWatchSection = ({ title, platforms }: WhereToWatchSectionProps) => {
  return (
    <Container
      className='where-to-watch'
      margin={['1.5rem auto', '2.5rem auto']}
      padding={{ default: '0 1rem', sm: '0 3.75rem' }}>
      <FlexContainer direction='column' gap='1.5rem'>
        <CategoryTitle uppercased withMarker tag='h2'>
          {title}
        </CategoryTitle>
        <PlatformsList platforms={platforms} />
      </FlexContainer>
    </Container>
  )
}

export default WhereToWatchSection
